import Image from "next/image";
import Link from "next/link";
import Navigation from "./Navigation";
import styles from "./styles.module.scss";
import {Button, ButtonGroup, Tooltip} from "@shopify/polaris";
import logo from "/public/common/logo.svg";

import {
  StoreMajor, ThemesMajor
} from '@shopify/polaris-icons';
import SideBar from "./SideBar";
import {APP_STORE_LINK, THEME_STORE_LINK} from "../../../utils/constants";
import useTranslation from "next-translate/useTranslation";
import {useCallback, useEffect, useRef} from "react";
import {useRouter} from "next/router";

const Header = () => {
  const {t} = useTranslation("header");
  const router = useRouter();
  const handleHome = useCallback(() => router.push("/"), [])

  return (
    <header className={styles["header"]}>
      <div className={styles["header__container"]}>
        <div className={styles["header__navigation"]}>
          <div className={styles["header__logo"]}>
            <Link href="/">
              <a className={styles["logo-link"]} aria-label={"home"} onClick={handleHome}>
                <Image src={logo} layout="responsive" quality={100} alt="DevIT.Software logo"/>
              </a>
            </Link>
          </div>
          <div className={styles["header__navigation-wrapper"]}>
            <Navigation/>
          </div>
        </div>
        <div className={styles["header__external-links"]}>
          <ButtonGroup>
            <Tooltip content={t("header:theme-tooltip")} active={false}>
              <span className={"tooltip-wrapper"}>
                <Button icon={ThemesMajor} external disabled>{t("header:shopifyThemeStore")}</Button>
              </span>
            </Tooltip>
            <Button icon={StoreMajor} external url={APP_STORE_LINK} primary>{t("shopifyApp")}</Button>
          </ButtonGroup>
        </div>
        <div className={styles["sidebar-wrapper"]}>
          <SideBar/>
        </div>
      </div>
    </header>
  )
}

export default Header;