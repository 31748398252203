import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import {useCallback, useState} from "react";
import {Popover, ActionList, Tooltip} from "@shopify/polaris";
import {useRouter} from "next/router";
import cn from "classnames";
import {RC, RC_SITE_LINK, RF, RF_SITE_LINK, SEL, SEL_SITE_LINK} from "../../../../utils/constants";

const Navigation = () => {
  const {t} = useTranslation("header");
  const router = useRouter()
  const [popoverActiveApp, setPopoverActiveApp] = useState(false);
  const [popoverActiveTheme, setPopoverActiveTheme] = useState(false);

  const togglePopoverActiveApp = useCallback(
    () => setPopoverActiveApp((popoverActive) => !popoverActive),
    [],
  );

  const togglePopoverActiveTheme = useCallback(
    () => setPopoverActiveTheme((popoverActive) => !popoverActive),
    [],
  );

  const handleApp = useCallback((route) => () => {
    router.push(route);
    setPopoverActiveApp(false);
  }, [])


  const isCurrentPage = (siteAppLink) => router.pathname.includes(siteAppLink);

  const activatorApp = (
    <div className={styles["navigation__header"]} onClick={togglePopoverActiveApp}>
      <p className={styles["navigation__header-text"]}>{t("applications.header")}</p>
    </div>
  );

  const activatorTheme = (
    <div className={styles["navigation__header"]} onClick={togglePopoverActiveTheme}>
      <Tooltip content={t("header:theme-tooltip")} active={false}>
        <p className={styles["navigation__header-text"]}>{t("themes.header")}</p>
      </Tooltip>
    </div>
  );

  return (
    <nav className={cn(styles["navigation"], "navigation")}>
      <div className={styles["navigation__block"]}>
        <Popover
          active={popoverActiveApp}
          activator={activatorApp}
          autofocusTarget="first-node"
          onClose={togglePopoverActiveApp}
          // fullHeight
          // fullWidth
        >
          <ActionList
            actionRole="menuitem"
            items={
              [
                {
                  content: <span className={cn({["current-page"]: isCurrentPage(SEL_SITE_LINK)})}>{SEL}</span>,
                  onAction: handleApp("selectors"),
                },
                {
                  content: <span className={cn({["current-page"]: isCurrentPage(RF_SITE_LINK)})}>{RF}</span>,
                  onAction: handleApp("react-flow"),
                },
                {
                  content: <span className={cn({["current-page"]: isCurrentPage(RC_SITE_LINK)})}>{RC}</span>,
                  onAction: handleApp("resell"),
                },
              ]
            }
          />
        </Popover>
      </div>
      <div className={cn(styles["navigation__block"], styles["themes-block"])}>
        <Popover
          active={popoverActiveTheme}
          activator={activatorTheme}
          autofocusTarget="first-node"
          onClose={togglePopoverActiveTheme}
        >
          <ActionList
            actionRole="menuitem"
          />
        </Popover>
      </div>
    </nav>
  )
}

export default Navigation;