import styles from "./styles.module.scss";
import cn from "classnames";
import {useCallback, useEffect, useState} from "react";
import Portal from "../../../../helpers/hocs/Portal";
import CollapsibleItem from "../../../common/elements/CollapsibleItem";
import {Button, ButtonGroup, Tooltip} from "@shopify/polaris";
import useTranslation from "next-translate/useTranslation";
import {APP_STORE_LINK} from "../../../../utils/constants";
import {StoreMajor, ThemesMajor} from "@shopify/polaris-icons";
import Link from "next/link";
import {useRouter} from "next/router";
import Logo from "../../../common/components/Icons/Logo/logo";
import LangSwitcher from "../../../common/components/LangSwitcher";

const SideBar = () => {
  const {t} = useTranslation("header");
  const [isActive, setActive] = useState(false);

  const handleActiveClick = useCallback(() => {
    setActive(true);
  }, [isActive])

  const handleCloseClick = useCallback(() => {
    setActive(false);
  }, [isActive])

  const sidebar = {
    list: [
      {
        text: t("sidebar.apps"),
        links: [
          {
            text: "Selectors",
            href: "/selectors"
          },
          {
            text: "React Flow",
            href: "/react-flow"
          },
          {
            text: "ReSell",
            href: "/resell"
          }
        ],
      },
      {
        text: t("sidebar.themes"),
        links: [
        ],
        disabled: true
      }
    ],
  }

  return (
    <aside className={cn(styles["sidebar-activator"], "sidebar")}>
      <div className={styles["sidebar-activator"]}>
        <button className={styles["sidebar-activator__burger"]} onClick={handleActiveClick} aria-label="Open sidebar">
          <span className={styles["sidebar-activator__burger-container"]}>
            <span className={styles["sidebar-activator__burger-stick"]}/>
          </span>
        </button>
      </div>
      <Portal>
        <>
          <div className={cn(styles["sidebar__overlay"], {[styles["overlay--active"]]: isActive})} onClick={handleCloseClick}/>
          <div className={cn(styles["sidebar"], {[styles["sidebar--active"]]: isActive})}>
            <div className={styles["sidebar__content"]}>
              <div className={styles["sidebar__header"]}>
                <Link href="/">
                  <a className={styles["logo-link"]}>
                    <Logo/>
                  </a>
                </Link>
                <div className={styles["sidebar__close-wrapper"]}>
                  <button className={styles["sidebar__close"]} onClick={handleCloseClick}>
                    <div className={styles["sidebar__close-stick"]}/>
                  </button>
                </div>
              </div>
              {sidebar.list.map((collapsibleItem, i) => (
                <CollapsibleItem
                  text={collapsibleItem.text}
                  links={collapsibleItem.links}
                  key={i}
                  disabled={collapsibleItem.disabled && collapsibleItem.disabled}
                  onClick={() => setActive(false)}
                />
              ))}
              <div className={styles["button-group"]}>
                <ButtonGroup fullWidth>
                  <Tooltip content={t("header:theme-tooltip")} active={false}>
                    <span className={"tooltip-wrapper"}>
                      <Button icon={ThemesMajor} external disabled fullWidth={true}>{t("header:shopifyThemeStore")}</Button>
                    </span>
                  </Tooltip>
                  <Button icon={StoreMajor} external url={APP_STORE_LINK} primary fullWidth>{t("shopifyApp")}</Button>
                </ButtonGroup>
              </div>
              <div className={styles["lang-switcher-wrapper"]}>
                <LangSwitcher colorType={"dark"}/>
              </div>
            </div>
          </div>
        </>
      </Portal>
    </aside>
  );
};

export default SideBar;